import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {modalConfig} from "../../config/modalConfig";
import type {Menu, MenuDetails} from "../../utils/types/MenusTypes";
import {ModalMenuContent} from "../../Pages/menu/ModelMenuContent";
import {MinimumProfile} from "./MinimumProfile";
import {PinStatus} from "./PinStatus";
import {Rating} from "./Rating";
import type {UserDetails} from "../../utils/types/UsersTypes";
import {usersConfig} from "../../config/usersConfig";
import {ModalUserContent} from "../../Pages/utilisateurs/ModalUserContent";
import {statusConfig} from "../../config/statusConfig";
import type {EventDetails, EventParticipation} from "../../utils/types/EventsTypes";
import {ModalEventContent} from "../../Pages/events/ModelEventContent";
import { useNavigate } from 'react-router-dom';

export const CustomModal = (props: {
    show: boolean,
    dataType: string,
    data: any | MenuDetails | UserDetails | EventParticipation,
    details?: any | Menu | UserDetails | EventDetails,
    handleClose: () => void,
    handleStatus: () => void,
    handleDelete?: () => void,
    refuseMenu?: () => void,
    handleEdit: () => void,
    userType?: string
    
}) => {
    const [modalTitle, setModalTitle] = useState('')
    const [isContinue, setIsContinue] = useState(false)
    const [canChangeStatus, setCanChangeStatus] = useState(false)
    const navigate = useNavigate();

    const [controlButtons, setControlButtons] = useState({
        validateButton: false,
        disableButton: false,
        refuseButton: false,
        editButton: false,
    })
    useEffect(() => {
        switch (props.dataType) {
            case modalConfig.dataType.menuType:
                if (props.data && props.data.name)
                    setModalTitle(props.data.name);
                break;
            case modalConfig.dataType.userType:
                if (props.data)
                    setModalTitle(props.data.nom + ' ' + props.data.prenom);
                break;
            case modalConfig.dataType.eventType:
                setModalTitle(props.data.evenement.title);
                break;
            default:
                console.log('you did not specify the good type')
        }
        
        setControlButtons({
            ...controlButtons,
            validateButton: (
                // menu in progress
                (props.dataType === modalConfig.dataType.menuType &&
                    props.data.status_dev === statusConfig.statusValues.inProgress)
                ||
                // chief or chief Medal not active
                (props.dataType === modalConfig.dataType.userType &&
                    (props.userType === usersConfig.usersType.chief ||
                        props.userType === usersConfig.usersType.chiefMedal)
                    && props.data.statut_compte === usersConfig.statusAccount.values.waiting)
                ||
                // chief validé + switch button changed to disable
                // || client + switch button changed to disable
                (props.dataType === modalConfig.dataType.userType &&
                    canChangeStatus
                    && (((props.userType === usersConfig.usersType.chief ||
                        props.userType === usersConfig.usersType.chiefMedal)
                        && props.data.statut_compte === usersConfig.statusAccount.values.active) )
                )
                ||
                    // event is waiting
                (props.dataType === modalConfig.dataType.eventType &&
                props.data.status === statusConfig.statusValues.waiting)
            ),
            disableButton: (props.dataType === modalConfig.dataType.menuType &&
                props.data.status_dev === statusConfig.statusValues.accepted),
            refuseButton: (
                // menu in progress
                (props.dataType === modalConfig.dataType.menuType &&
                    props.data.status_dev === statusConfig.statusValues.inProgress)
                ||
                // chief or chief medal waiting
                (props.dataType === modalConfig.dataType.userType &&
                    (props.userType === usersConfig.usersType.chief ||
                        props.userType === usersConfig.usersType.chiefMedal)
                    && props.data.statut_compte === usersConfig.statusAccount.values.waiting)
            ),
            editButton : (props.dataType === modalConfig.dataType.menuType &&
                props.data.status_dev === statusConfig.statusValues.accepted)
        });
        setIsContinue(true)
    }, [props.dataType, props.data, props.userType])
    const handleAction = () => {
        if (props.dataType === modalConfig.dataType.menuType) {
            props.handleStatus({'menu_id': props.details.id})
        }
        if (props.dataType === modalConfig.dataType.userType) {
            props.handleStatus({
                "user_type": "chef",
                "id": props.data.id,
                "status": canChangeStatus ?
                    usersConfig.statusAccount.values.waiting:
                    usersConfig.statusAccount.values.active
            })
        }
        if (props.dataType === modalConfig.dataType.eventType) {
            props.handleStatus({
                "participation_id": props.data.id,
                "status": statusConfig.statusValues.treaty
            })
        }
    }
    const handleRefuse = () => {
        // todo: this should be refactored
        if (props.dataType === modalConfig.dataType.userType) {
            props.handleStatus({
                "user_type": "chef",
                "id": props.data.id,
                "status": 'desactiver'
            })
        } else {
            if(props.dataType === modalConfig.dataType.menuType) {
                props.refuseMenu()
            }
        }
    }
    const handleDisableAction = () => {
        if(props.dataType === modalConfig.dataType.menuType
            && props.data.status_dev === statusConfig.statusValues.accepted ) {
            props.refuseMenu()
        } else {
        props.handleStatus({
            'menu_id': props.details.id,
            'status': statusConfig.statusValues.inProgress
        })
        }
    }
    const handleStatusChange = (event) => {
        console.log('----------------------------', event)
        if(props.userType === usersConfig.usersType.clients) {

        } else {
            setCanChangeStatus(!event)
        }
    }
    const handleDelete = (userId) => {
        props.handleDelete(userId, props.userType)
    }
    const handleEdit = () => {
        if (props.dataType === modalConfig.dataType.menuType &&
            props.data.status_dev === statusConfig.statusValues.accepted) {
                navigate('/EditMenu', { menuId: props.details.id });
                console.log("Édition du chef");
        } else {
            
        }
    };
    return (
        <>
            {isContinue &&
            <Modal
                show={props.show}
                onHide={props.handleClose}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title><span className="my-color">{modalTitle}</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(props.dataType === modalConfig.dataType.menuType) && <>
                        <PinStatus status={props.details.status_dev}/>
                        <MinimumProfile avatar={props.details.avatar}
                                        firstName={props.details.prenom}
                                        lastName={props.details.nom}
                        />
                        {(props.data.noter !== null) &&
                        <Rating rating={props.data.noter}/>
                        }
                        <ModalMenuContent data={props.data}/>
                    </>
                    }
                    {
                        (props.dataType === modalConfig.dataType.userType) && <>
                            <ModalUserContent data={props.data}
                                              handleStatusChange={handleStatusChange}
                                              handleDelete={handleDelete}
                                              dataType={props.userType}
                            />
                        </>
                    }
                    {
                        (props.dataType === modalConfig.dataType.eventType) && <>
                            <ModalEventContent data={props.data} />
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Fermer
                    </Button>
                    {controlButtons.refuseButton && <Button
                        variant="danger"
                        onClick={handleRefuse}>Refuser</Button> }
                    { controlButtons.validateButton && <Button
                        variant="success"
                        onClick={handleAction}>Valider</Button> }
                    { controlButtons.disableButton && <Button
                            variant="danger"
                            onClick={handleDisableAction}>Désactiver</Button> }
                            {controlButtons.editButton && (
                            <Button variant="primary" onClick={handleEdit}>
                                Modifier
                            </Button>
                                )}
                </Modal.Footer>
            </Modal>
            }
        </>
    );
}
