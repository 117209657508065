import React, {useEffect, useState} from "react";
import {AppBar, Avatar, IconButton, Tab, Tabs, Toolbar, Typography} from "@mui/material";
import {Link} from "react-router-dom"
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import logo from '../../images/logo.png'
import swal from "sweetalert";
import auth from "../../Services/auth";
import users from "../../Services/users";
import {env} from "../../config/env";
import {videMenuNotif} from "../../Services/notifs";

const ResponsiveAppBar = () => {
    const [setNom] = useState('');
    const [prenom, setPrenom] = useState("");
    const [value, setValue] = useState(0);
    useEffect(() => {
        let path = window.location.pathname
        if (path === "/dashboard" && value !== 0) setValue(0);
        else if (path === "/utilisateurs" && value !== 1) setValue(1);
        else if (path === "/menus" && value !== 2) setValue(2);
        else if (path === "/evenements" && value !== 3) setValue(3);
        else if (path === "/profil" && value !== 4) setValue(4);
        else if (path === "/planning" && value !== 5) setValue(5);
        else if (path === "/notifications" && value !== 6) setValue(6);

    }, [value,]);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const token = localStorage.getItem('access_token');

    const handleUser = async () => {
        const response = await users.adminDetails({});
        console.log(response);

        if ('admin' in response) {
            setNom(response.admin.nom)
            setPrenom(response.admin.prenom)
        } else {
            swal("Failed", "", "error"
            );
        }
    }

    const handlelogout = async e => {
        e.preventDefault();
        const response = await auth.logOutUser({});
        if (!('desc_info' in response)) {
            localStorage.clear();
            window.location.href = '/';
        } else {
            localStorage.clear();
            window.location.href = '/';
        }
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const getMenuEventNotif = () => {
        // todo: move api to an external file
        return fetch(env.backendUrl + "getMenuNotif",
            {
                headers: {
                    'content-type': 'application/json',
                    Authorization: ` Bearer ${token}`
                }
            })
            .then((response) => response.json())
            .then((actualData) => {
                    setbadgeCounterUser(actualData.utilisateurs)
                    setbadgeCounterEvent(actualData.evenments)
                    setbadgeCounterMenu(actualData.menus)
                    setbadgeCounterPlanning(actualData.planning)
                    setbadgeCounterNotifications(actualData.notifications)
                },
            );

    }

    const toolbarStyle = {
        minHeight: '2px',
        backgroundColor: 'white',
        boxShadow: "none", width: '100%', height: '50%', paddingLeft: '2%',
    };

    const settings = ['Deconnexion'];
    const [badgeCounterUser, setbadgeCounterUser] = React.useState(0);
    const [badgeCounterEvent, setbadgeCounterEvent] = React.useState(0);
    const [badgeCounterMenu, setbadgeCounterMenu] = React.useState(0);
    const [badgeCounterPlanning, setbadgeCounterPlanning] = React.useState(0);
    const [badgeCounterNotifications, setbadgeCounterNotifications] = React.useState(0);

    useEffect(() => {
        getMenuEventNotif().then()
        handleUser().then()
    }, [])

    const handleNotif = (data) => {
        videMenuNotif({
            type: data
        }).then(() => {
            switch (data) {
                case 'menus':
                    setbadgeCounterMenu(0)
                    break;
                case 'utilisateurs':
                    setbadgeCounterUser(0)
                    break;
                case 'evenments':
                    setbadgeCounterEvent(0)
                    break;
                case 'planning':
                    setbadgeCounterPlanning(0)
                    break;
                case 'notifications':
                    setbadgeCounterNotifications(0)
                    break;

            }
        })
    }

    return (
        <React.Fragment>
            <AppBar elevation={0}>
                <Toolbar style={toolbarStyle}>
                    <Typography> <img src={logo} alt="logo" style={{maxWidth: 200}}/>
                    </Typography>


                    <Tabs style={toolbarStyle} sx={{
                        color: 'rgb(141,126,83)',
                        backgroundColor: 'white',
                        "& .MuiTabs-flexContainer": {paddingTop: "12px", marginLeft: '10%'}
                    }}
                          textColor="inherit" value={value} onChange={handleChange}
                          TabIndicatorProps={{style: {background: '#EC6730'}}}>

                        <Tab style={{color: value === 0 ? "#EC6730" : ""}}
                             label="DASHBOARD" to='/dashboard' component={Link}/>

                        <Tab style={{color: value === 1 ? "#EC6730" : ""}}
                             onClick={() => {
                                 handleNotif('utilisateurs')
                             }}
                             label={<Badge sx={{p: 0.7}} badgeContent={badgeCounterUser} color="error"
                                           style={{transform: 'translate(2px, -0px)'}}>UTILISATEURS </Badge>}
                             to='/utilisateurs' component={Link}/>

                        <Tab style={{color: value === 2 ? "#EC6730" : ""}}
                             onClick={() => {
                                 handleNotif('menus')
                             }}
                             label={<Badge sx={{p: 0.7}} badgeContent={badgeCounterMenu} color="error"
                                           style={{transform: 'translate(2px, -0px)'}}>MENUS </Badge>} to='/menus'
                             component={Link}/>

                        <Tab style={{color: value === 3 ? "#EC6730" : ""}}
                             onClick={() => {
                                 handleNotif('evenments')
                             }}
                             label={<Badge sx={{p: 0.7}} badgeContent={badgeCounterEvent} color="error"
                                           style={{transform: 'translate(2px, -0px)'}}>EVENEMENTS </Badge>}
                             to='/evenements' component={Link}/>

                        <Tab style={{color: value === 4 ? "#EC6730" : ""}}
                             label="PROFIL" to='/profil' component={Link}/>

      
   
                        <Tab style={{color: value === 5 ? "#EC6730" : ""}}
                             onClick={() => {
                                 handleNotif('planning')
                             }}
                             label={<Badge sx={{p: 0.7}} badgeContent={badgeCounterPlanning} color="error"
                                           style={{transform: 'translate(2px, -0px)'}}>PLANNING</Badge>}
                             to='/planning' component={Link}/>

                        <Tab style={{color: value === 6 ? "#EC6730" : ""}}
                             onclick={() => {
                                handleNotif('notifications')
                             }}
                             label={<Badge sx={{p: 0.7}} badgeContent={badgeCounterNotifications} color="error"
                                            style={{transform: 'translate(2px, -0px)'}}>NOTIFICATIONS</Badge>}
                            to='/notifications' component={Link}/>
                    </Tabs>
                    <Tooltip title="Open settings">
                        <IconButton onClick={handleOpenUserMenu}
                                    sx={{marginLeft: '1%', color: 'rgb(165, 157, 133)', backgroundColor: 'white'}}>
                            <Avatar alt={prenom[0]} src="/static/images/avatar/2.jpg" sx={{marginRight: '300%'}}/>
                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{mt: '45px'}}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        {settings.map((setting) => (
                            <MenuItem key={setting} onClick={handlelogout}>
                                <Typography textAlign="center" color="#A79C87">{setting}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>


                </Toolbar>
            </AppBar>
        </React.Fragment>
    )
}


export default ResponsiveAppBar
