import React, { useEffect, useState } from "react";
import {env} from "../../config/env";

const token = localStorage.getItem('access_token');

const EditMenu = () => {
  const [titleMenu, setTitleMenu] = useState("");
  const [description, setDescription] = useState("");
  const [errorModal, setErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [menu, setIdMenu] = useState(null);
 

  const postEditMenu = async () => {
    try {
      setLoading(true);

      const response = await fetch(env.backendUrl + "getMenuDetails", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          menu: menu.id,
          name: titleMenu,
          description: description,
          status: "private",
        }),
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la requête.');
      }

      const data = await response.json();
    
 
      setTitleMenu(data.name);
      setDescription(data.description);
      setErrorModal(false);
      setIdMenu(data.menu_id);
      console.log('data-----', data);

      setLoading(false);
    } catch (error) {
      console.error('Erreur lors de la requête:', error);
      setLoading(false);
    }
  }

  const etapeUn = async (menuId: number) => {
    try {
      setLoading(true);
  
      if (!titleMenu || !description) {
        console.error('Veuillez remplir tous les champs requis.');
        setLoading(false);
        return;
      }
  
      const params = {
        name: titleMenu,
        description: description,
        status: "private",
      };
  
      const response = await fetch(env.backendUrl + "saveAdminMenuSteps_1" + menuId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(params),
      });
  
      if (!response.ok) {
        const errorMessage = await response.text(); // Récupérez le message d'erreur du serveur
        throw new Error(`Erreur lors de la requête. Réponse serveur : ${errorMessage}`);
      }
  
      const data = await response.json();
      console.log('Étape un terminée avec succès! Menu ID:', data.menu_id);
      setIdMenu(data.menu_id);  // Mettez à jour le menu_id avec la valeur dynamique
    } catch (error) {
      console.error('Erreur lors de l\'étape un:', error.message);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    postEditMenu();
  }, []);

  return (
    <div>
      <form>
        <label htmlFor="title">Titre :</label>
        <input
          type="text"
          id="title"
          value={titleMenu}
          onChange={(e) => setTitleMenu(e.target.value)}
        />

        <label htmlFor="description">Description :</label>
        <textarea
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        <button onClick={etapeUn} disabled={loading}>
          Continuer
        </button>
      </form>
    </div>
  );
}

export default EditMenu;
