export const PATHS = {
    Home: '/home',
    Login: '/',
    Confirmation: '/confirmation',
    Reset: '/reset',
    dash:'/dashboard',
    EditMenu:'/EditMenu',
    Utilisateur: '/utilisateurs',
    Menu: '/menus',
    Evenement: '/evenements',
    Profil:'/profil',
    Supression:'/supression',
    Planning: '/Planning',
    Notifications: 'Notifications'
  };
  