import React from 'react'
import Menu from "../Pages/menu/menu";
import {PATHS} from "./path";
import {Route, Routes} from "react-router-dom";
import Event from "../Pages/events/event";
import User from "../Pages/utilisateurs/user";
import ModalSuppression from "../components/modal/supression/modalSuppression";
import Login from "../Pages/auth/login";
import EditMenu from "../Pages/menu/EditMenu";
import Dashboard from "../Pages/dashboard/dashboard";
import Profile from "../Pages/profil/profile";
import Planning from '../Pages/planning/planning';
import Notifications from '../Pages/notifications/notifications';
export const RoutesList = () => {
  return (
    <Routes>
      <Route path={PATHS.dash} element={<Dashboard />} />
      <Route path={PATHS.Login} element={<Login />} />
      <Route path={PATHS.Utilisateur} element={<User />} />
      <Route path={PATHS.Menu} element={<Menu />} />
      <Route path={PATHS.Evenement} element={<Event />} />
      <Route path={PATHS.Profil} element={<Profile />} />
      <Route path={PATHS.Planning} element={<Planning />} /> 
      <Route path={PATHS.Supression} element={<ModalSuppression />}></Route>
      <Route path={PATHS.EditMenu} element={<EditMenu />} />
      <Route path={PATHS.Notifications} element={<Notifications/>} />
    </Routes>
  );
};
export default RoutesList;
