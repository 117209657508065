import Reset from "../Pages/auth/reset";
import Login from "../Pages/auth/login";
import Confirmation from "../Pages/auth/confirmation";
import { PATHS } from "./path";
import { Routes, Route } from "react-router-dom";
import React from 'react'
import EditMenu from "../Pages/menu/EditMenu";  

export const LoginRoutes = () => {
      return (
            <Routes>
                  <Route
                        path="/*"
                        element={
                              <Login />
                        }
                  />

                  <Route
                        path={PATHS.Reset}
                        element={
                              <Reset />
                        }
                  />
                  <Route
                        path={PATHS.Confirmation}
                        element={
                              <Confirmation  />
                        }
                  />
              
      <Route path={PATHS.EditMenu} element={<EditMenu />} />


            </Routes>
      );
};
export default LoginRoutes;
