import React, { Component } from 'react';
import { ViewState } from '@devexpress/dx-react-scheduler';
import { frFR } from '@mui/material/locale';
import {env} from "../../config/env";

import {
  Scheduler,
  MonthView,
  Toolbar,
  WeekView,
  DateNavigator,
  TodayButton,
  Appointments,
  AppointmentTooltip,

} from '@devexpress/dx-react-scheduler-material-ui';
import Checkbox from '@mui/material/Checkbox';
import moment from 'moment';
import { green, blue, blueGrey, pink, } from '@mui/material/colors';

const token = localStorage.getItem('access_token');

class Planning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availabilities: null,
      reservations: null,
      loading: true,
      selectedChefs: [],
      showAvailabilities: false,
      showReservations: false,
      selectAllChefs: false,
      searchText:'',
      locale: 'fr-FR',
      
    };
  }

  componentDidMount = () => {
    this.fetchDataReservation();
    this.fetchAvailabilities();
  }
  addAnHours = (date) => {
    const newDate = new Date(date); 
    newDate.setHours(newDate.getHours() + 1);
    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, '0');
    const day = String(newDate.getDate()).padStart(2, '0');
    const hours = String(newDate.getHours()).padStart(2, '0');
    const minutes = String(newDate.getMinutes()).padStart(2, '0');
    const seconds = String(newDate.getSeconds()).padStart(2, '0');

    const resultDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return resultDateTime;
}

  // formatage de date
  formatDate = (date) => {
    return moment(date).format('DD-MM-YYYY HH:mm');
  };

  toggleShowAvailabilities() {
    this.setState((prevState) => ({
      showAvailabilities: !prevState.showAvailabilities,
    }));
  }

  toggleShowReservations() {
    this.setState((prevState) => ({
      showReservations: !prevState.showReservations,
    }));
  }

  setValueReservation = (value) => {
    this.setState({ reservations: value });
  }

  fetchDataReservation = async () => {
    var requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    fetch(env.backendUrl + "getChefReservation", requestOptions)
    .then(response => response.json())
    .then(result => this.setValueReservation(result))
    .catch(error => console.log('error', error));
};
  

  setValueAvailabilities = (value) => {
    this.setState({ availabilities: value });
  }

  fetchAvailabilities = async () => {
    var requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    fetch(env.backendUrl + "getChefAvailabilities", requestOptions)
      .then(response => response.json())
      .then(result => this.setValueAvailabilities(result))
      .catch(error => console.log('error', error));
  };
 
  processAvailabilityData = (availabilities) => {
    const formattedData = [];

    availabilities.disponibilites.forEach((chefData) => {
      const { chef_id, dates, nom} = chefData;

      dates.forEach((dateData) => {
        const { date, matin, soir} = dateData;
        if (matin === 1) {
          formattedData.push({
            id: `${chef_id}-${date}-matin`,
            startDate: new Date(date + 'T08:00:00'),
            endDate: new Date(date + 'T12:00:00'),
            title: nom,
            chef_id: chef_id,
            chef_nom: nom,
            type: 'disponibilites',
          });
        }

        if (soir === 1) {
          formattedData.push({
            id: `${chef_id}-${date}-soir`,
            startDate: new Date(date + 'T18:00:00'),
            endDate: new Date(date + 'T23:00:00'),
            title: nom,
            chef_id: chef_id,
            chef_nom: nom,
            type: 'disponibilites',
          });
        }
      });
    });
    return formattedData;
  };

  processReservationData = (reservations) => {
    const { selectedChefs } = this.state;
    const formattedData = [];
    reservations.reservations.forEach((chefData) => {
      const { chef_id, dates, nom } = chefData;
     
        dates.forEach((date) => {
          formattedData.push({
            chef_id: chef_id,
            chef_nom: nom,
            startDate: new Date(date.replace(' ', 'T')),
            endDate:  new Date(this.addAnHours(date).replace(' ', 'T')),
            title: nom,
            type: 'reservation',
          });
        });
   
    });
    return formattedData;
  };
  
  handleChefCheckboxChange = (nom) => {
    if (this.state.selectedChefs.includes(nom)) {
      this.setState((prevState) => ({
        selectedChefs: prevState.selectedChefs.filter((chef) => chef !== nom),
      }), () => {
        console.log('Updated selectedChefs:', this.state.selectedChefs);
      });
    } else {
      this.setState((prevState) => ({
        selectedChefs: [...prevState.selectedChefs, nom],
      }), () => {
        console.log('Updated selectedChefs:', this.state.selectedChefs);
      });
    }
  };
  handleSelectAllChefs() {
    this.setState((prevState) => ({
      selectAllChefs: !prevState.selectAllChefs,
      selectedChefs: prevState.selectAllChefs
        ? [] 
        : this.state.availabilities.disponibilites.map((chef) => chef.nom), 
    }));
  }

  handleSearchChange = (event) => {
    this.setState({searchText: event.target.value});
  };

  CustomAppointmentContent = ({ children, data }) => (
    <Appointments.AppointmentContent {...data}>
      {children}
      <div>{this.formatDate(data.startDate)}</div>
      <div>{this.formatDate(data.endDate)}</div>
      <div>{data.title || 'Type non défini'}</div>
    </Appointments.AppointmentContent>
  );
  

  render() {
    const { showAvailabilities, showReservations, selectedChefs } = this.state;

    const availabilityData = showAvailabilities ? this.processAvailabilityData(this.state.availabilities) : [];
    const reservationData = showReservations ? this.processReservationData(this.state.reservations) : [];
    const combinedData = [...availabilityData, ...reservationData] ;
    const isChefSelected = this.state.selectedChefs.length > 0;

    const filteredData = combinedData.filter(item => selectedChefs.includes(item.chef_nom));
      

    return (
            <React.Fragment>

      <div className='txt-planning' > 
       <div style={{marginBottom: '10px'}}>
          <label  >
          Rechercher un chef
          <input
          type="text"
          value={this.state.searchText}
          onChange={this.handleSearchChange}
          className='filter-name-planning'  
/>
          </label>
          </div>
        <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
      
        <div className='chef'>
        <div >
          
          <label style={{marginRight: '10px' }}> 
            Afficher les disponibilités
            <input
              className="checkbox-custom"
               type="checkbox"
              checked={showAvailabilities}
              onChange={() => this.toggleShowAvailabilities()}
            />
          </label>
          <label>
            Afficher les réservations
            <input
            className="checkbox-custom"
              type="checkbox"
              checked={showReservations}
              onChange={() => this.toggleShowReservations()}
            /> 
          </label>
    
        </div> 
        
        <label style={{marginTop: '10px'}}>
            Tous les chefs
            {this.state.availabilities && this.state.availabilities.disponibilites && this.state.reservations && this.state.reservations.reservations ? (
            <Checkbox
              checked={this.state.selectAllChefs}
              onChange={() => this.handleSelectAllChefs()}
            />
            ) : <div>{'cc' + this.state.availabilities}</div>}
        
          </label>
        <div className="scroll" style={{overflow: 'scroll', height: '15%'}}>
     
          {this.state.availabilities && this.state.availabilities.disponibilites && this.state.reservations && this.state.reservations.reservations ? (
            this.state.reservations.reservations.filter(chef => {
              console.log(chef);
              //const fullName = `${chef.prenom} ${chef.nom}`;
              return chef.chef_nom.includes(this.state.searchText.toLowerCase());
            })
            .map((chef, index) => (
              <div key={index}>
                <Checkbox
                  checked={this.state.selectedChefs.includes(chef.chef_nom)}
                  onChange={() => this.handleChefCheckboxChange(chef.chef_nom)}
                />
                {chef.chef_nom ? `${chef.chef_nom}` : 'Nom non défini'}
                
              </div>
            ))
          ) : null}
        </div> 
        </div>
        <div style={{width: '70%'}}>
          {this.state.availabilities && this.state.availabilities.disponibilites && this.state.reservations && this.state.reservations.reservations ? (
            <Scheduler data={filteredData}
            locale="fr-FR"
            height={660}
            style={{ overflowX: 'auto' }}   >
              <ViewState
            defaultCurrentDate={moment().format('YYYY-MM-DD')}
          
     
          />
          
            <WeekView
            cellDuration={60} 
            startDayHour={7}
            endDayHour={24}  
             
          />
              <MonthView />
              <Toolbar  />
          <DateNavigator />
          <TodayButton messages={{ today: "Aujourd'hui" }}  />
          {isChefSelected ? ( 

              <Appointments
               contentComponent={this.CustomAppointmentContent} 
               />
              ) : null}
    <Appointments 
  appointmentComponent={(props) => {
    const { data, ...restProps } = props;
    const appointmentStyle = {
      backgroundColor: data.type === 'reservation' ? '#EC6730' : '#aaa',
      borderRadius: '8px',
      width: '100%',
      ...restProps.style,
    };
    return <Appointments.Appointment {...props} style={appointmentStyle}
 />;
  }}
 
/>

<AppointmentTooltip 

          />
        



            
            </Scheduler>
          ) : <div>{'cc' + this.state.availabilities}</div>}
        </div></div>
      </div>
            </React.Fragment>

    );
  }
}

export default Planning;

